var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"transfer-details"},[_c('h3',[_vm._v("Transfer Details")]),_c('p',[_vm._v("In order for your deposit to be effectively matched to your Transfersmile Wallet Merchant account, please provide the following details: ")]),_c('form',{ref:"form",staticClass:"deposit-component",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"3","label":"Amount","label-for":"amount-input"}},[_c('validation-provider',{attrs:{"name":"Amount","rules":"required|validAmount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount-input","type":"text","placeholder":"Please enter amount of funds sent.","value":_vm.depositAmount,"state":errors.length > 0 ? false : null,"autocomplete":"off","oninput":"value=value.replace(/[^\\d\\.]/g,'');if(value.split('.').length>1){value=Number(value.split('.')[0])+'.'+value.split('.')[1]}else{value=Number(value)}"},on:{"input":function (val) { return _vm.$emit('update:depositAmount', ("" + val)); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"3","label":"Date Sent"}},[_c('validation-provider',{attrs:{"name":"Date Sent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"date-sent",attrs:{"value":_vm.dateSend,"type":"date","placeholder":"Select datetime","confirm":true,"clearable":false,"value-type":"format"},on:{"change":function (val) { return _vm.$emit('update:dateSend', val); }}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("The Date Sent field is required")]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"3","label":"Payment Account","label-for":"payment-input"}},[_c('validation-provider',{attrs:{"name":"Payment Account","rules":"required|validAccountNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"payment-input","placeholder":"Please enter account number.","type":"text","value":_vm.paymentAccount,"state":errors.length > 0 ? false : null,"autocomplete":"off","oninput":"value=value.replace(/[^\\d\\-]/g,'');if(value.split('-').length>1){value=value.split('-')[0]+'-'+value.split('-')[1]}","formatter":function (value) { return _vm.sliceStr(value, 20); }},on:{"input":function (val) { return _vm.$emit('update:paymentAccount', ("" + val)); }}}),_c('div',[_vm._v("Please enter the account number from which funds were sent.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"3","label":"Remark","label-for":"remark-input"}},[_c('validation-provider',{attrs:{"name":"Remark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"remark-input","placeholder":"Please enter remark.","value":_vm.remark,"state":errors.length > 0 ? false : null,"autocomplete":"off","formatter":function (value) { return _vm.sliceStr(value, 100); }},on:{"input":function (val) { return _vm.$emit('update:remark', val); }}}),_c('div',[_vm._v("Please enter the remark accompanying the transfer ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"3","label":"Remittance Advice","label-for":"remittance-advice"}},[_c('b-overlay',{attrs:{"variant":"white","show":_vm.fileLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('div',{staticClass:"remittance-pic",style:(_vm.remittanceAdvice.length === 0 ? '' : 'opacity: 0;')},[_c('span',{staticClass:"remittance-icon"},[_vm._v("+")]),_c('b-form-file',{staticClass:"remittance-file",attrs:{"id":"remittance-advice","value":_vm.remittanceAdviceFile,"accept":".pdf,.jpg,.jpeg,.png,.bmp"},on:{"change":_vm.remittanceChange}})],1),(_vm.subUrlName(_vm.remittanceAdvice).includes('pdf'))?_c('b-link',{staticStyle:{"width":"150px","height":"150px","border":"1px dashed #cccccc","display":"block"},attrs:{"target":"_blank","href":_vm.remittanceAdvice}},[_c('img',{staticStyle:{"width":"100px","height":"100px","margin":"23px auto","display":"block"},attrs:{"src":require("@/assets/images/merchant/pdf.png"),"alt":"pdf"}})]):_c('div',{staticClass:"remittance-img"},[(_vm.remittanceAdvice.length > 0)?_c('img',{attrs:{"src":_vm.remittanceAdvice,"alt":_vm.remittanceAdvice}}):_vm._e()]),_c('div',[_vm._v("Please upload evidence of Payment.")]),(_vm.adviceError)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.adviceError))]):_vm._e()],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deposit-info"},[_c('h3',[_vm._v("Receiving Bank Information")]),_c('p',{staticClass:"mb-1"},[_vm._v(" Please use the following bank information to transfer the desired amount into your Transfersmile wallet merchant account. "),_c('br'),_vm._v("Please ensure information is entered accurately. ")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Beneficiary： ")]),_vm._v("TRANSFERSMILE INTERMEDIACAO E AGENCIAMENTO DE NEGOCIOS LTDA ")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("CPF/ CNPJ： ")]),_vm._v("23.010.551/0001-31 ")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Bank： ")]),_vm._v("218 - BCO BS2 S.A. ")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Branch： ")]),_vm._v("0001 ")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Account Number： ")]),_vm._v("845516-3 ")])])}]

export { render, staticRenderFns }