<template>
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card
        title=" "
        class="total-balance"
      >
        <b-row>
          <b-col md="3" />
          <b-col md="4">
            <div class="balance-box">
              <div class="balance-type">
                R$
              </div>
              <div>
                <p class="balance-num">
                  {{ balance || 0 }}
                </p>
                <p class="balance-text">
                  Total balance
                </p>
              </div>
            </div>
          </b-col>
          <b-col
            md="4"
            offset-md="1"
            class="balance-buttons"
          >
            <b-button
              v-hasBtn="`merchant_account`"
              variant="success"
              class="ml-2"
              @click="depositBtn"
            >
              Deposit
            </b-button>
            <b-button
              v-hasBtn="`merchant_account`"
              variant="primary"
              class="ml-2"
              @click="withdrawBtn"
            >
              Withdraw
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        no-body
        class="deposit-list"
      >
        <div class="mt-3 ml-2 mr-2">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="4">
                <b-form-group
                  class="mb-0"
                  label-cols="4"
                  label-cols-lg="3"
                  label="Date range"
                  label-class="text-center"
                >

                  <date-picker
                    :time-range-default="timeRangeDefault"
                    @TimeRange="getTimeRange"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="3"
                  label="Status"
                  label-for="status"
                  label-class="text-center"
                >
                  <v-select
                    id="status"
                    v-model="statusFilter"
                    :options="statusOptions"
                    class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
                    placeholder="All"
                    :reduce="val => val.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="Transaction Type"
                  label-for="transactionType"
                  label-class="text-center"
                >
                  <v-select
                    id="transactionType"
                    v-model="transactionTypeFilter"
                    :options="transactionTypeOptions"
                    class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
                    placeholder="All"
                    :reduce="val => val.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col class="text-center">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="search"
                >Search</b-button>
                <b-button
                  variant="secondary"
                  class="mr-1"
                  @click="reset"
                >Reset</b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-card>
      <table-list
        :table-data.sync="tableData"
        :list.sync="tableList"
        :page-num.sync="currentPage"
        :total-list.sync="totalList"
      />
    </b-overlay>
    <!-- modal -->
    <b-modal
      ref="deposit-modal"
      v-model="depositShow"
      :no-close-on-backdrop="true"
      title="Deposit"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="depositRules">
        <deposit-component
          :deposit-amount.sync="depositAmount"
          :date-send.sync="dateSend"
          :payment-account.sync="paymentAccount"
          :remark.sync="remark"
          :remittance-advice.sync="remittanceAdvice"
          :advice-error.sync="adviceError"
        />
      </validation-observer>
    </b-modal>
    <b-modal
      ref="withdraw-modal"
      v-model="withdrawShow"
      :no-close-on-backdrop="true"
      title="Withdraw"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      size="lg"
      centered
      @show="resetWithdrawModal"
      @hidden="resetWithdrawModal"
      @ok="handleWithdrawOk"
    >
      <validation-observer ref="withdrawRules">
        <withdraw-component
          :amount.sync="withdrawAmount"
          :bank-account.sync="bankAccount"
          :password.sync="password"
        />
      </validation-observer>
    </b-modal>

    <b-modal
      ref="image-modal"
      :no-close-on-backdrop="true"
      title="PROOF OF PAYMENT"
      cancel-variant="outline-secondary"
      size="lg"
      hide-footer
      centered
    >
      <b-img
        :src="modalImage"
        fluid
        alt="Responsive image"
        style="cursor: pointer;"
        @click="$previewPictrue(modalImage)"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, onMounted, onBeforeMount, watch, computed,
} from '@vue/composition-api'
import {
  BForm,
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTabs,
  BTab,
  BButtonToolbar,
  BButtonGroup,
  BFormGroup,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import DatePicker from '@/component/Datepicker.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  setDatetimeDefault, handlerTimestamp, showToast, aseEncrypt, subUrlName,
} from '@/libs/utils'
import depositComponent from './components/depositComponent.vue'
import withdrawComponent from './components/withdrawComponent.vue'
// import { createNamespacedHelpers } from "vuex";
// const { mapState: mapStateMerchant } = createNamespacedHelpers("wallet");
const moment = require('moment-timezone')

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const { $api, $store } = proxy
    const showLoading = ref(false)
    onBeforeMount(() => {
      // 获取公共数据
      if (store.state.wallet.countryList.length === 0) { store.dispatch('wallet/getCommon') }
      // if (store.state.wallet.bankList.length === 0) {  }
      store.dispatch('wallet/getBankList')
    })
    // 时区
    const timeZone = computed(() => $store.state.merchant.timeZone, { immediate: true, deep: true })
    // balance 模块
    const balance = ref('')
    // table 模块
    const timeRange = ref([])
    const timeRangeDefault = ref([])
    timeRangeDefault.value = [moment().format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
    const statusFilter = ref('-1')
    const transactionTypeFilter = ref('-1')
    // Succeed  = 1  Failed  = 2  Processing = 3
    const statusOptions = ref([
      { label: 'ALL', value: '-1' },
      { label: 'PROCESSING', value: '3' },
      { label: 'SUCCESS', value: '1' },
      { label: 'FAILED', value: '2' },
    ])
    // businessType 12 充值  13 提现
    // 1 PAYOUT(Withdraw) 2 PAYIN(Deposit)
    const transactionTypeOptions = ref([
      { label: 'ALL', value: '-1' },
      { label: 'WITHDRAW', value: '1' },
      { label: 'DEPOSIT', value: '2' },
    ])
    // 列表
    const modalImage = ref('')
    const tableCallback = (type, data) => {
      if (type === 'view') {
        if (data.attachment.includes('pdf')) {
          const el = document.createElement('a')
          document.body.appendChild(el)
          el.href = data.attachment
          el.target = '_blank'
          el.click()
          document.body.removeChild(el)
          return
        }
        proxy.$refs['image-modal'].show()
        modalImage.value = data.attachment
      }
    }

    const currentPage = ref(1)
    const tableList = ref([])
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'transaction_id', label: 'TRANSACTION ID' },
        { key: 'created_at_ms', label: 'CREATE TIME' },
        { key: 'updated_at_ms', label: 'UPDATE TIME' },
        // { key: 'merchant_id', label: 'MERCHANT ID' },
        { key: 'business_type_text', label: 'TYPE' },
        { key: 'amount', label: 'AMOUNT' },
        { key: 'processing_fee', label: 'PROCESSING FEE' },
        { key: 'status_text', label: 'STATUS' },
        { key: 'admin_remark', label: 'ADMIN REMARK' },
        { key: 'operation', label: 'PROOF OF PAYMENT' },
      ],
      templates: [
        { key: 'created_at_ms', tType: 'datetime' },
        { key: 'updated_at_ms', tType: 'datetime' },
        { key: 'amount', tType: 'account_list_amount' },
        { key: 'operation', tType: 'account_proof_of_payment', callback: tableCallback },
      ],
    })

    const getParams = () => {
      timeRange.value = handlerTimestamp(
        timeRangeDefault.value[0],
        timeRangeDefault.value[1],
        timeZone.value,
      )
      const params = {
        filter: {
          range: {
            begin: '',
            end: '',
          },
          status: Number(statusFilter.value),
          transaction_type: Number(transactionTypeFilter.value),
        },
        page_no: currentPage.value,
      }
      if (timeRange.value && timeRange.value.length > 0) {
        params.filter.range.begin = `${timeRange.value[0]}`
        params.filter.range.end = `${timeRange.value[1]}`
        // console.log('params.filter.range => ', JSON.stringify(params.filter.range))
      }
      return params
    }
    const initAccount = async () => {
      const params = getParams()
      showLoading.value = true
      const res = await $api.getAccount(params)
      showLoading.value = false
      const { code, data = {}, message } = res.data
      if (code === 200) {
        const { list, wallet_info: walletInfo, pagination } = data
        balance.value = Number(walletInfo.balance)
        tableList.value = list
        totalList.value = pagination && Number(pagination.total_count)
      } else {
        showToast({ proxy, title: 'danger', message })
      }
    }
    const getTimeRange = time => {
      timeRangeDefault.value = time
    }
    const search = () => { currentPage.value = 1; initAccount() }
    const reset = () => {
      statusFilter.value = '-1'
      transactionTypeFilter.value = '-1'
      timeRangeDefault.value = [moment().format('YYYY-MM-DD 00:00:00'), moment().format('YYYY-MM-DD 23:59:59')]
    }
    // watch([statusFilter, transactionTypeFilter], () => { initAccount() })
    // deposit弹框
    const depositShow = ref(false)
    const depositAmount = ref('') // 本次转账金额
    const dateSend = ref('')
    const paymentAccount = ref('') // 下拉选择银行账户
    const remark = ref('')
    const remittanceAdvice = ref('')
    const depositBtn = () => {
      // this.$swal({
      //   title: "提示",
      //   text: "用户未添加银行账户,请到setting中添加银行账户",
      //   customClass: {
      //     confirmButton: "btn btn-primary",
      //   },
      //   buttonsStyling: false,
      //   confirmButtonText: "Confirm",
      // }).then((result) => {
      //   if (result.value) {
      //     // alert(123);
      //   }
      // });
      proxy.$refs['deposit-modal'].show()
    }
    const resetModal = () => {
      depositAmount.value = ''
      dateSend.value = ''
      paymentAccount.value = ''
      remark.value = ''
      remittanceAdvice.value = ''
    }
    const adviceError = ref('')
    const handleOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      proxy.$refs.depositRules.validate().then(async success => {
        if (remittanceAdvice.value.length !== 0 && success) {
          adviceError.value = ''
          const params = {
            amount: depositAmount.value,
            sent_at: dateSend.value,
            payment_account_number: paymentAccount.value,
            remark: remark.value,
            payment_voucher: remittanceAdvice.value,
          }
          const res = await $api.deposit(params)
          const { code, message } = res.data
          if (code === 200) {
            proxy.$refs['deposit-modal'].hide()
            proxy.$swal({
            // title: 'Registration Processing',
              text: 'Your transaction is processing and you will be informed of the outcome within 48 hours of funds being recieved.',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/account/setting.png'),
              imageWidth: 88,
              imageAlt: 'Custom image',
              showConfirmButton: true,
              allowOutsideClick: false,
              customClass: {
                image: '',
              },
              position: 'center',
            })
            setTimeout(async () => { await initAccount() }, 0)
          } else {
            showToast({ proxy, title: 'danger', message })
          }
        } else {
          adviceError.value = 'Please enter your Remittance Advice.'
        }
      })
    }
    // withdraw弹框
    const withdrawShow = ref(false)
    const withdrawAmount = ref('')
    const bankAccount = ref('')
    const password = ref('')
    const withdrawBtn = () => {
      const bankListFilter = store.state.wallet.bankList
      const brazilBankListFilter = store.state.wallet.brazilBankList
      const mexicoBankListFilter = store.state.wallet.mexicoBankList
      if (bankListFilter.length > 0) {
        const newBankList = []
        bankListFilter.forEach(bank => {
          const newBank = bank
          if (bank.country === 'BRA') {
            brazilBankListFilter.forEach(br => {
              if (br.value === bank.bank_code) {
                newBank.label = `${br.label} (***${bank.value.slice(-4)})`
              }
            })
          } else if (bank.country === 'MEX') {
            mexicoBankListFilter.forEach(mex => {
              if (mex.value === bank.bank_code) {
                newBank.label = `${mex.label} (***${bank.value.slice(-4)})`
              }
            })
          }
          newBankList.push(newBank)
        })
        store.commit('wallet/SET_BANK_LIST', newBankList)
      }
      proxy.$refs['withdraw-modal'].show()
    }
    const resetWithdrawModal = () => {
      withdrawAmount.value = ''
      bankAccount.value = ''
      password.value = ''
    }
    const handleWithdrawOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      proxy.$refs.withdrawRules.validate().then(async success => {
        if (success) {
          const params = {
            amount: withdrawAmount.value,
            merchant_bank_id: bankAccount.value,
            password: aseEncrypt(password.value),
          }
          const res = await $api.withdraw(params)
          const { code, message } = res.data
          if (code === 200) {
            proxy.$refs['withdraw-modal'].hide()
            proxy.$swal({
              text: 'Your transaction is processing and you will be informed of the outcome within 48 hours.',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/account/setting.png'),
              imageWidth: 88,
              imageAlt: 'Custom image',
              showConfirmButton: true,
              allowOutsideClick: false,
              confirmButtonText: 'Okay!',
              customClass: {
                image: '',
              },
              position: 'center',
            })
            setTimeout(async () => { await initAccount() }, 0)
            // showToast({ proxy, title: 'success', message: 'withdraw success' })
          } else {
            showToast({ proxy, title: 'danger', message })
          }
        }
      })
    }
    onMounted(() => {
      setTimeout(async () => { await initAccount() }, 0)
    })
    watch([currentPage], async () => { await initAccount() })
    return {
      showLoading,
      balance,
      // func
      depositBtn,
      withdrawBtn,

      // 时区
      timeZone,
      // table相关
      timeRange,
      timeRangeDefault,
      statusFilter,
      transactionTypeFilter,
      getTimeRange,
      search,
      reset,
      statusOptions,
      transactionTypeOptions,

      tableData,
      tableList,
      modalImage,
      currentPage,
      totalList,
      initAccount,

      // deposit
      depositShow,
      depositAmount,
      dateSend,
      paymentAccount,
      remark,
      remittanceAdvice,
      resetModal,
      adviceError,
      handleOk,
      // withdraw
      withdrawShow,
      withdrawAmount,
      bankAccount,
      password,

      resetWithdrawModal,
      handleWithdrawOk,
    }
  },
  components: {
    BForm,
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
    DatePicker,
    BTabs,
    BTab,
    BButtonToolbar,
    BButtonGroup,
    BFormGroup,
    BImg,
    BOverlay,

    depositComponent,
    withdrawComponent,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },

})
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.total-balance {
  padding-bottom: 8px;
  .balance-box {
    display: flex;
    .balance-type {
      width: 67px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      margin-right: 20px;
      font-size: 20px;
      background-color: rgba(137, 127, 242, 1);
      border: none;
      border-radius: 7px;
      font-size: 28px;
      color: #FFFFFF;
    }
    p {
      margin-bottom: 0;
      line-height: 20px;
    }
    .balance-num {
      text-align: center;
      margin: 14px 0 10px;
      font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 37px;
      color: #9289F3;
    }
    .balance-text {
      font-family: 'Arial Cursiva', 'Arial Normal', 'Arial', sans-serif;
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: #7F7F7F;
    }
  }
  .balance-buttons {
    display: flex;
    align-items: center;
  }
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.b-form-flex {
  display: flex;
  flex-direction: row;
}
.b-col-top {
  margin-top: 1.1rem;
}
.deposit-list {
  ::v-deep .withdraw-table {
    .table tr td:nth-of-type(1) {
      word-break: break-all;
      word-wrap: break-all;
      min-width: 10rem;
    }
    .table tr td:nth-of-type(4) {
      min-width: 5rem;
    }
    .table tr td:nth-of-type(9) {
      min-width: 11rem;
    }
    .table tr td:nth-of-type(10) {
      min-width: 11rem;
    }
  }
  .button-active {
    background-color: #7367f0;
    color: #ffffff;
  }
  .btn-outline-primary.button-active:hover:not(.disabled):not(:disabled) {
    background-color: #7367f0;
    color: #ffffff;
  }
  .btn-outline-primary.button-active:not(.disabled):not(:disabled) {
    background-color: #7367f0;
    color: #ffffff;
  }
}
</style>
