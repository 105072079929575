<template>
  <div>
    <div class="deposit-info">
      <h3>Receiving Bank Information</h3>
      <p class="mb-1">
        Please use the following bank information to transfer the desired amount into your Transfersmile wallet merchant account. <br>Please ensure information is entered accurately.
      </p>
      <p class="mb-1">
        <strong>Beneficiary： </strong>TRANSFERSMILE INTERMEDIACAO E AGENCIAMENTO DE NEGOCIOS LTDA
      </p>
      <p class="mb-1">
        <strong>CPF/ CNPJ： </strong>23.010.551/0001-31
      </p>
      <p class="mb-1">
        <strong>Bank： </strong>218 - BCO BS2 S.A.
      </p>
      <p class="mb-1">
        <strong>Branch： </strong>0001
      </p>
      <p class="mb-1">
        <strong>Account Number： </strong>845516-3
      </p>
    </div>
    <div class="transfer-details">
      <h3>Transfer Details</h3>
      <p>In order for your deposit to be effectively matched to your Transfersmile Wallet Merchant account, please provide the following details: </p>
      <form
        ref="form"
        class="deposit-component"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="Amount"
          label-for="amount-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required|validAmount"
          >
            <b-form-input
              id="amount-input"
              type="text"
              placeholder="Please enter amount of funds sent."
              :value="depositAmount"
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              oninput="value=value.replace(/[^\d\.]/g,'');if(value.split('.').length>1){value=Number(value.split('.')[0])+'.'+value.split('.')[1]}else{value=Number(value)}"
              @input="(val) => $emit('update:depositAmount', `${val}`)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="Date Sent"
        >
          <validation-provider
            #default="{ errors }"
            name="Date Sent"
            rules="required"
          >
            <date-picker
              :value="dateSend"
              class="date-sent"
              type="date"
              placeholder="Select datetime"
              :confirm="true"
              :clearable="false"
              value-type="format"
              @change="(val) => $emit('update:dateSend', val)"
            />
            <small
              v-if="errors.length > 0"
              class="text-danger"
            >The Date Sent field is required</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="Payment Account"
          label-for="payment-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Payment Account"
            rules="required|validAccountNumber"
          >
            <b-form-input
              id="payment-input"
              placeholder="Please enter account number."
              type="text"
              :value="paymentAccount"
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              oninput="value=value.replace(/[^\d\-]/g,'');if(value.split('-').length>1){value=value.split('-')[0]+'-'+value.split('-')[1]}"
              :formatter="value => sliceStr(value, 20)"
              @input="(val) => $emit('update:paymentAccount', `${val}`)"
            />
            <div>Please enter the account number from which funds were sent.</div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="Remark"
          label-for="remark-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Remark"
            rules="required"
          >
            <b-form-input
              id="remark-input"
              placeholder="Please enter remark."
              :value="remark"
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              :formatter="value => sliceStr(value, 100)"
              @input="(val) => $emit('update:remark', val)"
            />
            <div>Please enter the remark accompanying the transfer </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="Remittance Advice"
          label-for="remittance-advice"
        >
          <b-overlay
            variant="white"
            :show="fileLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <div
              class="remittance-pic"
              :style="remittanceAdvice.length === 0 ? '' : 'opacity: 0;'"
            >
              <span class="remittance-icon">+</span>
              <!-- v-model="remittanceAdviceFile" -->
              <b-form-file
                id="remittance-advice"
                :value="remittanceAdviceFile"
                class="remittance-file"
                accept=".pdf,.jpg,.jpeg,.png,.bmp"
                @change="remittanceChange"
              />
            </div>
            <b-link
              v-if="subUrlName(remittanceAdvice).includes('pdf')"
              target="_blank"
              :href="remittanceAdvice"
              style="width: 150px;height: 150px;border: 1px dashed #cccccc;display: block;"
            >
              <img
                style="width: 100px;height: 100px;margin: 23px auto;display: block;"
                src="@/assets/images/merchant/pdf.png"
                alt="pdf"
              >
            </b-link>
            <div
              v-else
              class="remittance-img"
            >
              <img
                v-if="remittanceAdvice.length > 0"
                :src="remittanceAdvice"
                :alt="remittanceAdvice"
              >
            </div>
            <div>Please upload evidence of Payment.</div>
            <small
              v-if="adviceError"
              class="text-danger"
            >{{ adviceError }}</small>
          </b-overlay>
        </b-form-group>
      </form>
    </div>

  </div>
</template>

<script>
import { defineComponent, ref, getCurrentInstance } from '@vue/composition-api'
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { showToast, sliceStr, subUrlName } from '@/libs/utils'
import useAccountVerification from '../../pages/accountVerification/useAccountVerification'

extend('validAmount', {
  validate(value) {
    return /^\d+(|\.[\d+]{1,3})$/.test(`${value}`.trim())
  },
  message: 'The {_field_} is not valid',
})

extend('validAccountNumber', {
  validate(value) {
    return value.length >= 4
  },
  message: 'The {_field_} is not valid',
})

export default defineComponent({
  // name: 'depositComponent',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    VBModal,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BOverlay,

    vSelect,
    DatePicker,

    ValidationObserver,
    ValidationProvider,
  },
  setup(props, { emit }) {
    const fileLoading = ref(false)
    const { proxy } = getCurrentInstance()
    const { uploadFile } = useAccountVerification()
    const remittanceAdviceFile = ref(null)
    const remittanceChange = async event => {
      if (event.target.files.length === 0) {
        return
      }
      const [singleFile] = event.target.files
      // 限制上传文件的大小
      if (singleFile.size > 10 * 1024 * 1024) {
        showToast({ proxy, title: 'danger', message: 'The file cannot exceed 10mb' })
        return
      }
      fileLoading.value = true
      const url = await uploadFile(singleFile)
      fileLoading.value = false
      emit('update:adviceError', '')
      emit('update:remittanceAdvice', url)
    }

    return {
      fileLoading, remittanceAdviceFile, remittanceChange, sliceStr, subUrlName,
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    depositAmount: {
      type: String,
      default: '',
    },
    dateSend: {
      type: String,
      default: '',
    },
    paymentAccount: {
      type: String,
      default: '',
    },
    remark: {
      type: String,
      default: '',
    },
    remittanceAdvice: {
      type: String,
      default: '',
    },
    adviceError: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.deposit-info {
  border: 2px solid #e18484;
  border-radius: 15px;
  padding: 10px 20px 0;
  margin-bottom: 20px;
  h3 {
    text-align: center;
  }
}
.transfer-details {
  width: 85%;
  margin: 0 auto;
  h3 {
    text-align: center;
  }
  .deposit-component {
    .width-select {
      width: 100%;
    }
  }
  .date-sent {
    width: 100%;
  }
}
.remittance-pic {
  width: 150px;
  height: 150px;
  position: absolute;
  border: 1px dashed #eaedf0;
  cursor: pointer;
  .remittance-icon {
    font-size: 100px;
    position: absolute;
    line-height: 150px;
    text-align: center;
    width: 150px;
    height: 150px;
    color: #969899;
    cursor: pointer;
  }
  .remittance-file {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.remittance-img {
  width: 150px;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
