<template>
  <div>
    <p class="pl-1 pr-1">
      Please confirm withdraw details to submit your withdraw request.
    </p>
    <form
      ref="form"
      class="withdraw-component"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Amount"
        label-for="amount-input"
      >
        <validation-provider
          #default="{ errors }"
          name="Amount"
          rules="required|validAmount|compare"
        >
          <b-form-input
            id="amount-input"
            :value="amount"
            type="text"
            :state="errors.length > 0 ? false:null"
            placeholder="Please enter amount of funds sent"
            oninput="value=value.replace(/[^\d\.]/g,'');if(value.split('.').length>1){value=Number(value.split('.')[0])+'.'+value.split('.')[1]}else{value=Number(value)}"
            @input="(val) => $emit('update:amount', `${val}`)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Bank Account"
        label-for="bank-account-select"
        invalid-feedback="Bank Account is required"
      >
        <validation-provider
          #default="{ errors }"
          name="Bank Account"
          rules="required"
        >
          <v-select
            id="bank-account-select"
            :value="bankAccount"
            :options="bankList"
            class="invoice-filter-select mr-sm-1 no-clear-select width-select"
            placeholder="Example Acct Name (***3425)"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:bankAccount', val)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Avaliable amount"
        invalid-feedback="Avaliable amount is required"
      >
        <b-form-input
          v-model="availableAmount"
          disabled
        />
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Email"
        invalid-feedback="Email is required"
      >
        <b-form-input
          v-model="availableEmail"
          disabled
        />
      </b-form-group>
      <b-form-group
        label-cols="5"
        label-cols-lg="4"
        label="Password"
        label-for="bank-password-select"
        invalid-feedback="Password is required"
      >
        <validation-provider
          #default="{ errors }"
          name="Password"
          rules="required|password:5|reg"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="password-input"
              :value="password"
              :type="passwordFieldType"
              :state="errors.length > 0 ? false:null"
              placeholder="Enter Password"
              autocomplete="new-password"
              @input="(val) => $emit('update:password', val)"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </form>
  </div>
</template>

<script>
import {
  defineComponent, ref, reactive, getCurrentInstance, computed,
} from '@vue/composition-api'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import storage from '@/libs/storage'

export default defineComponent({
  props: {
    amount: {
      type: String,
      default: '',
    },
    bankAccount: {
      type: String,
      default: '',
    },
    password: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    // const { $api } = proxy
    // console.log($api)
    const bankList = computed(() => proxy.$store.state.wallet.bankList)
    // const bankList = [{ label: 'abc', value: 'abc' }]
    const userData = storage.getItem('userData')
    const { accountInfo, basicInfo } = userData
    const availableAmount = ref(accountInfo.balance)
    const availableEmail = ref(basicInfo.company_email)

    extend('validAmount', {
      validate(value) {
        return /^\d+(|\.[\d+]{1,3})$/.test(`${value}`.trim())
      },
      message: 'The {_field_} is not valid',
    })
    extend('compare', {
      validate(value) {
        return Number(value) <= Number(availableAmount.value)
      },
      message: 'The amount is less than or equal to the avaliable amount',
    })
    extend('password', {
      validate(value, { min }) {
        return value.length >= min
      },
      params: ['min'],
      message:
        'The password must be a combination of at least 5 numbers and letters.',
    })
    extend('reg', {
      validate(value) {
        return /^[a-zA-Z0-9]\w{5,32}$/.test(value)
      },
      message: 'The {_field_} is not valid',
    })
    const passwordFieldType = ref('password')
    const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
    }
    return {
      availableAmount,
      availableEmail,
      bankList,
      required,
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,

    vSelect,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.withdraw-component {
  .width-select {
    width: 100%;
  }
  .b-append-btn {
    & > button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .phone-number-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
